import React, {
  Fragment,
  FunctionComponent,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  ColumnDef,
  ColumnSizingState,
  FilterFn,
  PaginationState,
  Row,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import GridItem from '../../layout/GridComponents/GridItem';
import clsx from 'clsx';

import { useDispatch } from 'react-redux';
import { Button, FormControlLabel, Switch } from '@mui/material';

import { addComponentToPdfExport } from '../../../redux/pdfExport/actions';
import { PdfComponentType } from '../../../types/redux/pdfExports/pdfExportsStore';
import { ultraTableStyles } from './styles/ultratable.styles';
import { RankingInfo } from '@tanstack/match-sorter-utils';

import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
} from '@dnd-kit/sortable';
import { getPaginationRowModel } from '@tanstack/react-table';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import TablePaginator from './components/TablePaginator';
import TableSearch from './components/TableSearch';
import ExportButton from '../../feedback/ExportButton';
import { DraggableTableHeader } from './components/DraggableTableHeader';
import PopoverColumnSelector from './components/PopoverColumnSelector';
import UltraTableSubTable from './components/UltraTableSubTable';
import { useVirtualizer } from '@tanstack/react-virtual';
import { RowData } from '@tanstack/react-table';
import {
  createDefaultColumnVisibility,
  fuzzyFilter,
  getColumnOrder,
} from './utils/ultratable.utils';
import { getCommonPinningStyles } from './styles/extras.styles';
import { countTrueValues } from './utils/buildUltraTableData';
import UltraTableProvider, { useUltraTable } from './context/UltraTableContext';
import './styles/ultratable.css';
import { UltraTablePreset } from './types/presets.types';
import { GeneralFunctionType } from './types/functions.types';
import { EditableFields } from './components/EditableCell';
import { isEqual } from 'lodash';
import { ColumnFields } from './types/column.types';
import { Legend } from './components/Legend';

declare module '@tanstack/react-table' {
  //add fuzzy filter to the filterFns
  interface FilterFns {
    fuzzy?: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }

  interface TableMeta<TData extends RowData> {
    updateData: (
      rowIndex: number,
      columnId: string,
      value: unknown,
      resetOld?: boolean,
    ) => void;
  }
}

function useSkipper() {
  const shouldSkipRef = React.useRef(true);
  const shouldSkip = shouldSkipRef.current;

  // Wrap a function with this to skip a pagination reset temporarily
  const skip = React.useCallback(() => {
    shouldSkipRef.current = false;
  }, []);

  React.useEffect(() => {
    shouldSkipRef.current = true;
  });

  return [shouldSkip, skip] as const;
}

type UltraTableProps<T, P> = {
  title?: string;
  tableData: TableData<T>;

  getRowCanExpand: (row: Row<T>) => boolean;
  pdfConfig?: {
    pdfExportGroupName?: string;
    pdfExportGroupOrder?: number;
    pdfIdentifier: string;
    pdfTitle?: string;
    pdfFileName?: string;
  };
  aggregation?: {
    value: boolean;
    setter: React.Dispatch<React.SetStateAction<boolean>>; // Whether aggregation of positions is possible
  };
  positionDate?: string;
  nestedTables?: {
    // This is a nested buildTableData function which returns an array of data types
    buildDataFn: (input: any) => P[];
    buildColumnsFn: <P>() => ColumnDef<P>[];
  };
  // isEditable?: {
  //   fn: GeneralFunctionType; // General function to handle the update of a cell
  // };

  isEditable?: boolean; // Whether the table is editable
  onDataComparison?: (isDataEqualToOriginal: boolean) => void; // Function to handle the comparison of the data // TODO: Rethink better - We need to reduce state inconsistancies and figure a way to make editable cells set pending/updated states to parent component

  actionBtns?: React.ReactElement<HTMLButtonElement>[]; // Array of action buttons to be displayed in the toolbar - TODO: Rethink better
  presets?: UltraTablePresetConfig; // Handles the preset capibilities of the table
  columnSelector?: { select: boolean; group: boolean }; // Display the column popover selector
  exclusions?: string[]; // This is a quick workaround to hardcode columns that are never filtered out
};

export type UltraTablePresetConfig = {
  default: UltraTablePreset;
  allPresets: UltraTablePreset[];
  context?: UltraTablePreset;
};

export type SmallDropdownUnderlyingPosition = {
  name: string;
  client_price: number;
  delta: string;
  position_size: string;
  risk_factor: string;
  lc_exposure: number;
  bc_exposure: number;
  gross_exposure: number;
  commitment: number;
};

export type TableData<T> = {
  data: T[];
  columns: ColumnDef<T>[];
};

const getOldValue = (value: any) => {
  if (typeof value === 'object' && value !== null && 'old' in value) {
    return value.old;
  }
  return value;
};

// The main positions table component, which will be exported from the file
const UltraTableComponent = <T extends EditableFields, P>({
  title,
  tableData,
  getRowCanExpand,
  pdfConfig,
  aggregation,
  nestedTables,
  isEditable,
  actionBtns,
  presets,
  columnSelector,
  onDataComparison,
  positionDate,
  exclusions,
}: UltraTableProps<T, P>): React.ReactElement => {
  const classes = ultraTableStyles();
  const dispatch = useDispatch();
  const { updateLog, setUpdateLog } = useUltraTable();

  // Not ideal solution for identifying selected preset but only general solution I could think of for now
  const [selectedPreset, setSelectedPreset] = useState(presets?.default);

  const [globalFilter, setGlobalFilter] = React.useState('');

  //   Is memoisation required here? Unknown
  // const {
  //   columns,
  //   data: originalData,
  //   selected_position_date,
  // } = useMemo(() => {
  //   return {
  //     columns: tableData.columns,
  //     data: tableData.data,
  //     selected_position_date: tableData.selected_position_date,
  //   };
  // }, [tableData]);

  const { columns, data: originalData } = {
    columns: tableData.columns,
    data: tableData.data,
  };

  const defaultColumnVisibility = useMemo(() => {
    return createDefaultColumnVisibility(columns, presets);
  }, [presets, columns]);

  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(
    defaultColumnVisibility,
  );

  // Hacky way to render the newly visibly columns
  useEffect(() => {
    setColumnVisibility(defaultColumnVisibility);
  }, [defaultColumnVisibility]);

  // GOD AWFUL WAY OF SETING THE CONTEXT PRESETS - THINK BETTER MR BARNEVELD
  useEffect(() => {
    if (!presets?.context?.columns) return;

    setColumnVisibility(() => {
      const vis: VisibilityState = {};

      columns.forEach((col) => {
        if (col.id !== undefined) {
          vis[col.id] = presets.context?.columns.includes(col.id) ?? false;
        }
      });
      return vis;
    });
  }, [presets?.context, columns]);

  // REFACTOR THIS COMPUTER CODE MR BARNEVELD
  const [columnOrder, setColumnOrder] = React.useState<string[]>(
    getColumnOrder(columns, exclusions || [], isEditable),
  );

  // TODO: This is legacy code for the export pdf - leave untouched for now - rethink later
  // function to get an array of chosen column id's in selected order
  const getChosenColumsInOrder = (): string[] => {
    const orderedChoices: string[] = [];
    columnOrder.forEach((col) => {
      // [ pending removal ] 04/11/2022 Tom Walsh
      // this code relates to notes column which we have chosen to temporarily remove, maybe making permanent
      // if (columnVisibility[col] === true && col !== 'all' && col !== 'notes') {
      //   orderedChoices.push(col);
      // }

      // replacement code
      if (columnVisibility[col] === true && col !== 'index') {
        orderedChoices.push(col);
      }
      //
    });
    return orderedChoices;
  };

  // reorder columns after drag & drop
  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      setColumnOrder((columnOrder) => {
        const oldIndex = columnOrder.indexOf(active.id as string);
        const newIndex = columnOrder.indexOf(over.id as string);
        return arrayMove(columnOrder, oldIndex, newIndex); //this is just a splice util
      });
    }
  }

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {}),
  );

  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10000,
  });

  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();
  const [data, setData] = React.useState(originalData); // issues with rerendering on mutation invalidation

  const isDataEqualToOriginal = isEqual(data, originalData);

  useEffect(() => {
    if (onDataComparison) {
      onDataComparison(isDataEqualToOriginal);
    }
  }, [isDataEqualToOriginal, onDataComparison]);

  const mainRenderedTable = useReactTable<T>({
    data,
    columns,
    state: {
      columnVisibility,
      columnOrder,
      pagination,
      globalFilter,
    },

    initialState: {
      columnPinning: {
        left: ['index'],
      },
    },

    onColumnVisibilityChange: setColumnVisibility,

    filterFns: {
      fuzzy: fuzzyFilter, // Define as a filter function that can be used in column definitions
    },

    autoResetPageIndex,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: 'fuzzy', // Apply fuzzy filter to the global filter (most common use case for fuzzy filter)

    columnResizeMode: 'onChange',
    onColumnOrderChange: setColumnOrder,
    getRowCanExpand,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),

    meta: {
      updateData: (rowIndex, columnId, value, resetOld) => {
        // Skip page index reset until after next rerender

        if (resetOld) {
          setData((old) =>
            old.map((row, index) => {
              if (index === rowIndex) {
                return {
                  ...old[rowIndex]!,
                  [columnId]: value,
                };
              }
              return row;
            }),
          );
          return;
        }

        skipAutoResetPageIndex();
        setData((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              const updatedRow = {
                ...old[rowIndex]!,
                [columnId]: {
                  old: getOldValue(old[rowIndex]![columnId]),
                  updated: value,
                },
              };
              return updatedRow;
            }
            return row;
          }),
        );
      },
    },
  });

  // // Hacky fix to set pagination if the row count is less than 10,000
  // React.useEffect(() => {
  //   setPagination({
  //     pageIndex: 0,
  //     pageSize:
  //       mainRenderedTable.getRowCount() < 10000
  //         ? mainRenderedTable.getRowCount()
  //         : 10000,
  //   });
  // }, [mainRenderedTable]);

  // This is not an optimal solution to reponsive cells
  // TODO: Refer to reactmaterialtable to understand responsive css function patterns
  useEffect(() => {
    // Call handleResize on first load
    const handleResize = () => {
      // Max the minimum divison by 14 columns to avoid hyper squishing
      const totalShownColumns = Math.min(countTrueValues(columnVisibility), 14); // This causes the sizing issues to make generic I need to pass the minus window with and the minum division

      // 100px estimated padding + index size
      const MIN_SIZE = (window.innerWidth - 100) / totalShownColumns;
      const newColumnSizes: ColumnSizingState = {};

      Object.keys(columnVisibility).forEach((columnId: string) => {
        if (columnVisibility[columnId]) {
          if (columnId === 'name') {
            newColumnSizes[columnId] = Math.max(100, MIN_SIZE);
          } else {
            newColumnSizes[columnId] = MIN_SIZE;
          }
        }
      });

      // This is the only way to update the column sizes
      // Listed here setSize function required in future - https://github.com/TanStack/table/discussions/5558
      mainRenderedTable.setColumnSizing(() => {
        const obj: Record<string, number> = {};
        columns.forEach((col) => {
          if (col.id !== undefined && col.id !== 'index') {
            obj[col.id] = newColumnSizes[col.id];
          }
        });
        return obj;
      });
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [columnVisibility, mainRenderedTable]);

  // function to convert react-table row model data to export ready data
  const convertRowModelForExport = (rows: Row<T>[]) => {
    return rows.map((row) => row.original);
  };

  // function to handle pdf export
  const tablePdfExportHandler = async () => {
    const renderColumns: any = [];
    const chosenColumns = getChosenColumsInOrder();
    chosenColumns.map((chosCol) => {
      const item = columns.filter((col) => col.id === chosCol)[0];
      renderColumns.push({
        header: item.header,
        dataKey: item.id,
        render: item.cell,
      });
    });
    return {
      startY: 50,
      columns: renderColumns,
      body: convertRowModelForExport(mainRenderedTable.getRowModel().rows),
    };
  };

  const tableFiltered =
    (mainRenderedTable.getState().columnFilters.length &&
      mainRenderedTable.getState().columnFilters.length > 0) ||
    mainRenderedTable.getState().globalFilter;

  const [pdfIdentifier] = useState<string>(`${pdfConfig?.pdfIdentifier}`);

  // When column selections update, dispatch changes in pdf export data to redux
  useEffect(() => {
    dispatch(
      addComponentToPdfExport({
        identifier: pdfIdentifier,
        handler: tablePdfExportHandler,
        type: PdfComponentType.TABLE,
        title: `${pdfConfig?.pdfTitle || pdfConfig?.pdfIdentifier}${
          tableFiltered ? '(Filtered)' : ''
        }`,
        dontMoveToNewPage: true,
        groupName: pdfConfig?.pdfExportGroupName ?? undefined,
        groupOrder: pdfConfig?.pdfExportGroupOrder ?? undefined,
      }),
    );
  }, [columns, columnOrder, tableFiltered, columnVisibility]);

  const returnFilteredRows = (rows: Row<T>[]) => {
    const newRows = rows.filter((row) => {
      // Map out all the editable fields key names
      const editableFieldKeys = row.original.editable_fields.map(
        (field) => field.key_name,
      );

      // Check if every selectedPreset column exists in the editable fields or is in the exclusions list
      return (
        selectedPreset?.columns.every((column) => {
          return (
            (exclusions || []).includes(column as ColumnFields) ||
            editableFieldKeys.includes(column)
          );
        }) ?? false
      );
    });

    return newRows;
  };

  // Hide cells if not editable or if no preset is selected
  const hideCells =
    !isEditable ||
    selectedPreset?.id === 'default' ||
    selectedPreset === undefined
      ? false
      : true;

  const rows = hideCells
    ? returnFilteredRows(mainRenderedTable.getRowModel().rows)
    : mainRenderedTable.getRowModel().rows;

  // Virtualiser
  const tableContainerRef = React.useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 50, //estimate row height for accurate scrollbar dragging
    getScrollElement: () => tableContainerRef.current,
    //measure dynamic row height, except in firefox because it measures table border height incorrectly
    measureElement:
      typeof window !== 'undefined' &&
      navigator.userAgent.indexOf('Firefox') === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  });

  function handleResetFilters() {
    setSelectedPreset(presets?.default);
    setColumnVisibility(defaultColumnVisibility);
    mainRenderedTable.resetColumnFilters();
  }

  const test = mainRenderedTable.getVisibleLeafColumns().length - 1; // this is because 1px is added to each column to account for border right - its not ideal but it works - figure better css solution

  const tableWidth = mainRenderedTable.getCenterTotalSize() - test - 10;

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToHorizontalAxis]}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      {/* These are helpful for debugging the table */}
      {/* <pre>
        {JSON.stringify(
          { columnFilters: mainRenderedTable.getState().columnFilters },
          null,
          2
        )}
      </pre> */}
      {/* <pre style={{ fontSize: '1rem' }}>
        {JSON.stringify(mainRenderedTable.getState().columnOrder, null, 2)}
      </pre> */}

      {/* <pre style={{ fontSize: '1rem' }}>
        {JSON.stringify(updateLog, null, 2)}
      </pre> */}

      <GridItem xs={12} card>
        <div className={classes.toolbar}>
          <h2 className={classes.header}>
            {title} {tableFiltered ? '(Filtered)' : ''}
          </h2>

          <div className={classes.toolbarOptions}>
            <div className={classes.assetClassButtonsContainer}>
              {presets &&
                presets.allPresets.map((preset) => {
                  const columns = mainRenderedTable.getAllLeafColumns();
                  const selected = selectedPreset?.id == preset.id;
                  return (
                    <Button
                      aria-describedby={preset.name}
                      variant="text"
                      disableElevation
                      key={preset.id}
                      className={
                        selected
                          ? clsx(
                              classes.assetClassButton,
                              classes.activeAssetClassButton,
                            )
                          : classes.assetClassButton
                      }
                      onClick={() => {
                        if (selected) {
                          setSelectedPreset(presets?.default);
                          setColumnVisibility(defaultColumnVisibility);
                        } else {
                          //Set the visibility of the columns to the preset visibility
                          setSelectedPreset(preset);
                          setColumnVisibility(() => {
                            const vis: VisibilityState = {};

                            columns.forEach((col) => {
                              if (preset.columns.includes(col.id)) {
                                vis[col.id] = true;
                              } else {
                                vis[col.id] = false;
                              }
                            });
                            return vis;
                          });
                        }
                      }}
                      value={preset.id}
                    >
                      {preset.name}
                    </Button>
                  );
                })}
              <TableSearch
                className={classes.search}
                onChange={setGlobalFilter}
              />
            </div>

            <div className={classes.mainOptionsContainer}>
              <div className={classes.mainOptions}>
                {aggregation && (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={aggregation.value}
                        onChange={() => aggregation.setter((prev) => !prev)}
                        className={classes.switch}
                      />
                    }
                    label={'Aggregate Table'}
                    className={classes.switchContainer}
                  />
                )}
                {actionBtns &&
                  actionBtns.map((btn, index) => <div key={index}>{btn}</div>)}
                {columnSelector && (
                  <PopoverColumnSelector // Can pass the generic type here if needed
                    buttonName={'Choose Columns'}
                    columns={mainRenderedTable.getAllColumns()}
                    setColumnOrder={setColumnOrder}
                    table={mainRenderedTable}
                    handleResetFilters={handleResetFilters}
                    groups={columnSelector.group}
                  />
                )}

                <Button
                  aria-describedby={'reset'}
                  variant="text"
                  disableElevation
                  onClick={handleResetFilters}
                  className={classes.resetButton}
                >
                  Reset Filters
                </Button>
                <ExportButton
                  exportData={convertRowModelForExport(
                    mainRenderedTable.getRowModel().rows,
                  )}
                  pdfIdentifier={`${pdfIdentifier}`}
                  fields={getChosenColumsInOrder()}
                  fileName={pdfConfig?.pdfFileName || pdfConfig?.pdfIdentifier}
                  selectedPositionDate={positionDate}
                  allowPdfExport={true}
                />
                {/* <span
                  style={{
                    fontSize: '1.4rem',
                    minHeight: '20px',
                    marginInline: '.25rem',
                  }}
                >
                  {tableFiltered
                    ? `Results: ${mainRenderedTable.getRowCount()}`
                    : ''}
                </span> */}
              </div>
            </div>

            {mainRenderedTable.getRowCount() > 10000 && (
              <TablePaginator table={mainRenderedTable} />
            )}
          </div>
        </div>

        {isEditable && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Legend
              values={[
                'Editable cells',
                'Local edits (unsubmitted)',
                'Update request failed',
              ]}
            />
            {/* <button
              disabled={updateLog.length === 0}
              className="submit-btn"
              onClick={() => {
                updateLog.forEach((update) =>
                  isEditable?.fn(update.original, update.columnId, update.value)
                );
                // Reset the update log - this will occur even on errors which is a nightmare for users but fuck it for now
                setUpdateLog([]);
              }}
            >
              Submit
            </button> */}
          </div>
        )}

        <div ref={tableContainerRef} className={classes.tableContainer}>
          <table
            className={classes.table}
            style={{
              margin: 'auto',
              display: 'grid',
              width: tableWidth,
            }}
          >
            <thead
              style={{
                position: 'sticky',
                top: 0,
                zIndex: 1,
                backgroundColor: 'white',
                width: tableWidth,
              }}
            >
              {mainRenderedTable.getHeaderGroups().map((headerGroup) => (
                <tr
                  key={headerGroup.id}
                  className={classes.headerRow}
                  style={{ display: 'flex', width: '100%' }}
                >
                  <SortableContext
                    items={columnOrder}
                    strategy={horizontalListSortingStrategy}
                  >
                    {headerGroup.headers.map((header) =>
                      header.id !== 'index' ? (
                        <DraggableTableHeader
                          key={header.id}
                          header={header}
                          classes={classes}
                          table={mainRenderedTable}
                        />
                      ) : (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                          className={classes.expanderHead}
                          style={{ ...getCommonPinningStyles(header.column) }}
                        >
                          {header.isPlaceholder ? null : (
                            <>
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext(),
                              )}
                            </>
                          )}
                        </th>
                      ),
                    )}
                  </SortableContext>
                </tr>
              ))}
            </thead>
            <tbody
              style={{
                height: `${rowVirtualizer.getTotalSize()}px`, // tells scrollbar how big the table is
                position: 'relative', // needed for absolute positioning of rows
                width: tableWidth,
              }}
            >
              {rowVirtualizer.getVirtualItems().length ? (
                rowVirtualizer.getVirtualItems().map((virtualRow, index) => {
                  const row = rows[virtualRow.index] as Row<T>;

                  return (
                    <Fragment key={row.id}>
                      <tr
                        data-index={virtualRow.index} // needed for dynamic row height measurement
                        ref={(node) => rowVirtualizer.measureElement(node)} // measure dynamic row height
                        className={classes.row}
                        style={{
                          display: 'flex',
                          position: 'absolute',
                          transform: `translateY(${virtualRow.start}px)`, // this should always be a `style` as it changes on scroll
                          width: '100%',
                        }}
                        // onClick={(event) => console.log('row clicked', row.id)}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <td
                            key={cell.id}
                            className={classes.cell}
                            style={{
                              width:
                                mainRenderedTable.getState().columnSizing[
                                  cell.column.id
                                ],
                              ...getCommonPinningStyles(cell.column),
                            }}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </td>
                        ))}
                      </tr>
                      {row.getIsExpanded() &&
                        aggregation?.value &&
                        nestedTables && (
                          <tr
                            style={{
                              width: '100%',
                              display: 'flex',
                              position: 'relative',
                              transform: `translateY(${
                                virtualRow.start + virtualRow.size - 4 // This hack is to account for borderWidth - not ideal but works for absolute elements
                              }px)`, // this should always be a `style` as it changes on scroll
                            }}
                          >
                            <td
                              colSpan={row.getVisibleCells().length}
                              style={{ width: '100%' }}
                            >
                              <UltraTableSubTable<P>
                                data={nestedTables.buildDataFn(row.original)}
                                columns={nestedTables.buildColumnsFn<P>()}
                              />
                            </td>
                          </tr>
                        )}
                    </Fragment>
                  );
                })
              ) : (
                <tr>
                  <td
                    style={{
                      width: tableWidth,
                    }}
                    className={classes.noDataMessage}
                  >
                    No Matches For Current Filters
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </GridItem>
    </DndContext>
  );
};

// We are wrapping the ultra table so we can have a component higher context
const UltraTable = <T extends EditableFields, P>(
  props: UltraTableProps<T, P>,
) => {
  return (
    <UltraTableProvider>
      <UltraTableComponent {...props} />
    </UltraTableProvider>
  );
};

export default UltraTable;
