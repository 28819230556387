import { useContext } from 'react';
import { ConfirmationContext } from '../components/context/ConfirmationContext';

const useConfirmation = () => {
  const { confirm } = useContext(ConfirmationContext);

  const handleConfirmation = (
    title: string,
    message: string,
    onConfirm?: any,
  ) => {
    confirm(title, message, onConfirm);
  };

  return { handleConfirmation };
};

export default useConfirmation;
